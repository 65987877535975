import React, { Suspense } from "react"
import ErrorBoundary from "./ErrorBoundary"
import RequireAuth from "./RequireAuth"
import Loader from "./Loader"

const ProtectedRoute = ({ children, ...rest }) => {
    return (
        <ErrorBoundary>
            <RequireAuth {...rest} >
                <Suspense fallback={(<Loader isLoading={true} />)}>{children}</Suspense>
            </RequireAuth>
        </ErrorBoundary>
    )
}

export default ProtectedRoute