import "./ButtonWithNav.css"
import React from "react"
import { NavLink, useMatch, useResolvedPath } from "react-router-dom"
import { Button } from "@mui/material"

function ButtonWithNav({ children, to, ...props }) {
    if (to) {
        return (
            <ItemWithNav component={NavLink} to={to} {...props}>
                {children}
            </ItemWithNav>
        )
    }
    return (
        <Button {...props}>
            {children}
        </Button>
    )
}

function ItemWithNav({ children, to, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: false })

    return (
        <Button className={isActive ? "navActive" : ""} component={NavLink} to={to} {...props}>
            {children}
        </Button>
    )
}

export default ButtonWithNav