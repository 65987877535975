import { useState, createContext, useContext, Fragment, useEffect } from "react"
import { Route, useLocation, Navigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

import LogRocket from "logrocket"

const REFRESH_TIME = 60 * 20 // 20 minutes

//https://auth0.com/docs/libraries/auth0-react
function RequireAuth({ children }) {
    const { user, isAuthenticated, isLoading, loginWithRedirect, error } = useAuth0()
    const [needsRefresh, setNeedsRefresh] = useState(false)
    const location = useLocation()

    useEffect(() => {
        if (!isLoading && isAuthenticated && user)
            LogRocket.identify(user.sub, {
                name: user.name,
                email: user.email,

                // Add your own custom user variables here, ie:
                identities: user.identities
            })
    }, [user, isAuthenticated, isLoading])

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (error || !isAuthenticated) {
        loginWithRedirect()
        return (<div>Redirecting to login...</div>)
    }

    return (<Fragment>{children}</Fragment>)
}

export default RequireAuth