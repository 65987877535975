import "./Home.css"
import React, { useEffect, useState, } from "react"
import { Navigate } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { Container, Stack } from "@mui/material"

function Page(props) {
    // Check if we are logged in with auth0
    const { isAuthenticated } = useAuth0()

    // Redirect to dashboard if logged in
    if (isAuthenticated) {
        return <Navigate to="/dashboard" replace />
    }

    return (
        // <Container className="splashContainer" maxWidth="xl">
        <Container className="splashContainer" maxWidth="md">
            <Stack height="100%" width="100%" spacing={2} alignItems="center" justifyContent="center" flexDirection="column">
                <a href="https://marketrentals.com">
                    <img className="splashLogo" src="/images/MarketRentals-logo_V4.1.png" alt="Holtzman Market Rentals" />
                </a>
            </Stack>
        </Container>
    )
}

export default Page
