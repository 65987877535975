import React, { useMemo } from "react"
import { InputBase } from "@mui/material"
import { Menu as MenuIcon, Search as SearchIcon } from "@mui/icons-material"
import { styled, alpha } from "@mui/material/styles"
import { throttle } from "lodash"

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
    marginRight: theme.spacing(1),
}))

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        width: "100%",
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}))


function SearchBar({ placeholder, onSearch, requireSubmit = false, ...rest }) {
    const [value, setValue] = React.useState("")
    const onChange = (e) => {
        setValue(e.target.value)
        if (!requireSubmit) {
            onSearch(e.target.value)
        }
    }

    const onSubmit = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            onSearch(value)
        }
    }

    const throttledChangeHandler = useMemo(
        () => throttle(onChange, 400)
        , [])

    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                {...rest}
                placeholder={placeholder ?? "Search…"}
                onChange={throttledChangeHandler}
                onKeyUp={onSubmit}
                inputProps={{ "aria-label": "search" }}
            />
        </Search>
    )
}

export default SearchBar