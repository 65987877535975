import React, { Suspense } from "react"
import ErrorBoundary from "./ErrorBoundary"

const UnprotectedRoute = ({ children, loadingFallback, errorFallback, ...rest }) => {
    return (
        <ErrorBoundary>
            <Suspense fallback={loadingFallback}>{children}</Suspense>
        </ErrorBoundary>
    )
}

export default UnprotectedRoute