import "./Support.css"
import React, { Fragment, useEffect, useState } from "react"
import { Container, Typography, Link } from "@mui/material"
import validator from "@rjsf/validator-ajv6"
import Form from "@rjsf/mui"

const SUPPORT = "aG1yc3VwcG9ydEBjYXppcmV0aC5jb20="

const uiSchema = {
    "message": {
        "ui:widget": "textarea"
    }
}

const schema = {
    title: "Support",
    type: "object",
    required: ["name", "subject", "message"],
    properties: {
        name: {
            type: "string",
            title: "Your name"
        },
        "subject": {
            type: "string",
            title: "Subject"
        },
        message: {
            type: "string",
            title: "Message"
        }
    }
}

function Support(props) {
    const [data, setData] = useState({})

    const onChange = ({ formData }) => {
        setData(formData)
    }

    const onSubmit = () => {
        if (data.name != null && data.subject != null && data.message != null) {
            window.location = encodeURI(`mailto:${atob(SUPPORT)}?subject=${data.subject}&body=${data.message}`)
        }
    }
    const onError = (e) => {
        console.log(e)
    }

    return (
        <Container maxWidth="lg">
            <Form
                validator={validator}
                schema={schema}
                uiSchema={uiSchema}
                onSubmit={onSubmit}
                onError={onError}
                onChange={onChange}
                formData={data}
                noHtml5Validate />
        </Container >

    )
}

export default Support
