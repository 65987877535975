import React from "react"
import { useLocation } from "react-router-dom"

function NoMatchPage() {
    let location = useLocation()
    return (
        <div>No match for <code>{location.pathname}</code></div>
    )
}

export default NoMatchPage