import "./LinkWithNav.css"
import React from "react"
import { NavLink } from "react-router-dom"
import { Link } from "@mui/material"

function CustomComponent({ children, ...props }) {
    return (
        <Link component={NavLink} {...props}>
            {children}
        </Link>
    )
}

export default CustomComponent