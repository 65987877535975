import "./MenuItemWithNav.css"
import React from "react"
import { NavLink, useMatch, useResolvedPath } from "react-router-dom"
import { MenuItem } from "@mui/material"

function MenuItemWithNav({ children, to, ...props }) {
    if (to) {
        return (
            <ItemWithNav to={to} {...props}>
                {children}
            </ItemWithNav>
        )
    }

    return (
        <MenuItem {...props}>
            {children}
        </MenuItem>
    )

}

function ItemWithNav({ children, to, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: false })

    return (
        <MenuItem className={isActive ? "navActive" : ""} component={NavLink} to={to} {...props}>
            {children}
        </MenuItem>
    )
}

export default MenuItemWithNav