import "./App.css"
import React, { lazy } from "react"
import { Route, Routes, BrowserRouter } from "react-router-dom"
import Layout from "./layout"
import Support from "./pages/Support"
import Home from "./pages/Home"
import NoMatchPage from "./pages/NoMatchPage"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { CssBaseline, Box } from "@mui/material"
import { useLocalStorage } from "./hooks/useStorage"
import useEnvironment from "./hooks/useEnvironment"
import { ThemeReducer, ThemeContext } from "./reducers/ThemeReducer"
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook"
import ProtectedRoute from "./components/ProtectedRoute"
import UnprotectedRoute from "./components/UnprotectedRoute"

// Enable code splitting for logged in elements
const Account = lazy(() => import("./pages/Account"))
const Dashboard = lazy(() => import("./pages/Dashboard"))
const Rent = lazy(() => import("./pages/Rent"))
const Manage = lazy(() => import("./pages/Manage"))
const Reports = lazy(() => import("./pages/Reports"))
const ChangeLog = lazy(() => import("./pages/ChangeLog"))

const ManageHome = lazy(() => import("./pages/manage/ManageHome"))
const ManageRenters = lazy(() => import("./pages/manage/ManageRenters"))
const ManageCompanies = lazy(() => import("./pages/manage/ManageCompanies"))
const ManageHomeEdit = lazy(() => import("./pages/manage/ManageHomeEdit"))
const ManageRentersEdit = lazy(() => import("./pages/manage/ManageRentersEdit"))
const ManageCompaniesEdit = lazy(() => import("./pages/manage/ManageCompaniesEdit"))

// Manage Cleanings
const ManageCleanings = lazy(() => import("./pages/manage/ManageCleanings"))
const ManageCleaningsEdit = lazy(() => import("./pages/manage/ManageCleaningsEdit"))
// Manage Buildings
const ManageBuildings = lazy(() => import("./pages/manage/ManageBuildings"))
const ManageBuildingsEdit = lazy(() => import("./pages/manage/ManageBuildingsEdit"))
// Manage Neighborhoods
const ManageNeighborhoods = lazy(() => import("./pages/manage/ManageNeighborhoods"))
const ManageNeighborhoodsEdit = lazy(() => import("./pages/manage/ManageNeighborhoodsEdit"))
// Manage Markets
const ManageMarkets = lazy(() => import("./pages/manage/ManageMarkets"))
const ManageMarketsEdit = lazy(() => import("./pages/manage/ManageMarketsEdit"))

const GeneralSettings = lazy(() => import("./pages/settings/General"))
const PrivacySettings = lazy(() => import("./pages/settings/Privacy"))
const NotificationSettings = lazy(() => import("./pages/settings/Notification"))
const AccountSettings = lazy(() => import("./pages/settings/Account"))

const RentHomeSearchPage = lazy(() => import("./pages/rent/RentHomeSearchPage"))
const RentRenterSearchPage = lazy(() => import("./pages/rent/RentRenterSearchPage"))
const RentDetailPage = lazy(() => import("./pages/rent/RentDetailPage"))

const gtmParams = { id: "G-79MFTPEK0H" }

function App() {
    const [lsStyle] = useLocalStorage("theme", "light")
    const [StyleProvider, stylePref] = useEnvironment("theme", lsStyle, ThemeReducer, ThemeContext, () => { })

    const themeOptions = {
        palette: stylePref === "light" ? {
            mode: "light",
            primary: {
                main: "#394a59",
            },
            secondary: {
                main: "#355818",
            },
            error: {
                main: "#d41818",
            },
            background: {
                default: "#f2f2f2",
                paper: "#fff",
            },
            text: {
                primary: "#394a59",
                secondary: "rgba(170,59,59,0.7)",
            },
        } : {
            mode: "dark",
            primary: {
                main: "#70a649",
            },
            secondary: {
                main: "#5A8CA6",
            },
            error: {
                main: "#d41818",
            },
            background: {
                default: "#121212",
                paper: "#121212",
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        scrollbarColor: "#6b6b6b #2b2b2b",
                        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                            backgroundColor: "#2b2b2b",
                            width: 4,
                        },
                        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                            backgroundColor: "#6b6b6b",
                        },
                        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                            backgroundColor: "#959595",
                        },
                        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                            backgroundColor: "#959595",
                        },
                        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#959595",
                        },
                        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                            backgroundColor: "#2b2b2b",
                        },
                    },
                },
            },
        },
    }

    const theme = createTheme(themeOptions)

    return (
        <ThemeProvider theme={theme}>
            <StyleProvider>
                <GTMProvider state={gtmParams}>
                    <Box className="App">
                        <CssBaseline />
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Layout />}>

                                    <Route index element={<UnprotectedRoute ><Home /></UnprotectedRoute>} />

                                    <Route path="dashboard" element={<ProtectedRoute ><Dashboard /></ProtectedRoute>} />
                                    <Route path="rent" element={<ProtectedRoute ><Rent /></ProtectedRoute>} >
                                        <Route path="homeSearch" element={<RentHomeSearchPage />} />
                                        <Route path="renterSearch" element={<RentRenterSearchPage />} />
                                        <Route path="details" element={<RentDetailPage />} />
                                        <Route path=":id" >
                                            <Route path="homeSearch" element={<RentHomeSearchPage />} />
                                            <Route path="renterSearch" element={<RentRenterSearchPage />} />
                                            <Route path="details" element={<RentDetailPage />} />
                                        </Route>
                                    </Route>
                                    <Route path="changelog" element={<ProtectedRoute ><ChangeLog /></ProtectedRoute>} />
                                    <Route path="reports" element={<ProtectedRoute ><Reports /></ProtectedRoute>} />
                                    <Route path="manage" element={<ProtectedRoute ><Manage /></ProtectedRoute>}>
                                        {/* TODO: Redo these to take in these to ManageEntities and ManageEnityEdit and just pass params */}
                                        <Route path="homes" element={<ManageHome />} />
                                        <Route path="homes/:id" element={<ManageHomeEdit />} />
                                        <Route path="renters" element={<ManageRenters />} />
                                        <Route path="renters/:id" element={<ManageRentersEdit />} />
                                        <Route path="companies" element={<ManageCompanies />} />
                                        <Route path="companies/:id" element={<ManageCompaniesEdit />} />

                                        <Route path="cleanings" element={<ManageCleanings />} />
                                        <Route path="cleanings/:id" element={<ManageCleaningsEdit />} />
                                        <Route path="buildings" element={<ManageBuildings />} />
                                        <Route path="buildings/:id" element={<ManageBuildingsEdit />} />
                                        <Route path="neighborhoods" element={<ManageNeighborhoods />} />
                                        <Route path="neighborhoods/:id" element={<ManageNeighborhoodsEdit />} />
                                        <Route path="markets" element={<ManageMarkets />} />
                                        <Route path="markets/new" element={<ManageMarketsEdit />} />
                                        <Route path="markets/:id" element={<ManageMarketsEdit />} />
                                    </Route>
                                    <Route path="settings" element={<ProtectedRoute ><Account /></ProtectedRoute>}>
                                        <Route index element={<ProtectedRoute ><GeneralSettings /></ProtectedRoute>} />
                                        <Route path="account" element={<ProtectedRoute ><AccountSettings /></ProtectedRoute>} />
                                        <Route path="privacy" element={<ProtectedRoute ><PrivacySettings /></ProtectedRoute>} />
                                        <Route path="notifications" element={<ProtectedRoute ><NotificationSettings /></ProtectedRoute>} />
                                    </Route>
                                    <Route path="support" element={<UnprotectedRoute ><Support /></UnprotectedRoute>} />
                                    <Route path="*" element={<NoMatchPage />} />
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </Box >
                </GTMProvider>
            </StyleProvider>
        </ThemeProvider>
    )
}

export default App
