import "./index.css"
import React, { Fragment } from "react"
import { Stack } from "@mui/material"
import Header from "./Header"
import Content from "./Content"
import Footer from "./Footer"

function Layout() {
    return (
        <Fragment>
            <Stack
                className="layout"
                direction="column" >
                <Header />
                <Content />
                <Footer />
            </Stack>
        </Fragment>
    )
}

export default Layout