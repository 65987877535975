import "./Footer.css"
import React from "react"
import { Link, Typography } from "@mui/material"
import LinkWithNav from "../components/LinkWithNav"
import { Stack, Divider } from "@mui/material"
import { useAuth0 } from "@auth0/auth0-react"

function Footer() {
    const { isAuthenticated } = useAuth0()
    return (
        <footer>
            <Stack
                className="bottomNav"
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2} >
                <LinkWithNav to="support" color="secondary" variant="caption">Support</LinkWithNav>
                {isAuthenticated && <LinkWithNav to="changelog" color="secondary" variant="caption">Change Log</LinkWithNav>}
            </Stack>
            <Link href="https://cazireth.com" underline="hover" color="secondary">
                <Typography
                    variant="caption"
                    align="center">
                    ©️ {new Date().getFullYear()} Cazireth LLC.
                </Typography>
            </Link>
        </footer>
    )
}

export default Footer