import "./Loader.css"
import React, { Fragment } from "react"
import { Box, CircularProgress } from "@mui/material"
import { useTheme } from "@mui/material/styles"

function Loader({ isLoading, children }) {
    const theme = useTheme()
    if (isLoading) {
        return (
            <Fragment>
                <Box className="loaderCentered">
                    <CircularProgress />
                </Box>
            </Fragment>
        )
    }
    return (
        <Fragment>{children}</Fragment>
    )
}

export default Loader