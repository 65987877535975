import "./Header.css"
import React, { useEffect, useState } from "react"
import {
    Avatar, AppBar, Container, Toolbar,
    Typography, Box, IconButton, Menu, Tooltip,
} from "@mui/material"
import { Menu as MenuIcon } from "@mui/icons-material"
import { useAuth0 } from "@auth0/auth0-react"
import MenuItemWithNav from "../components/MenuItemWithNav"
import ButtonWithNav from "../components/ButtonWithNav"
import { getContext } from "../hooks/useEnvironment"
import { changeTheme } from "../reducers/ThemeReducer"
import SearchBar from "../components/SearchBar"
import ThemeSwitch from "../components/ThemeSwitch"

function makeNavUrl(page) {
    return page.callback == null ? page.to ?? `/${page.name.toLowerCase().replace(" ", "")}` : null
}

function Header() {
    const [anchorElNav, setAnchorElNav] = useState(null)
    const [anchorElUser, setAnchorElUser] = useState(null)
    const { loginWithRedirect, logout, user, isLoading, isAuthenticated, error } = useAuth0()
    const ThemeContext = getContext("theme")
    const handleThemeSwitch = (dispatch) => (event) => {
        changeTheme(dispatch, event.target.checked ? "dark" : "light")
    }

    // const AUTHENTICATED_PAGES = ["Rentals", "Home Owners", "Renters", "Companies"];
    const AUTHENTICATED_PAGES = [
        { "name": "Dashboard" },
        { "name": "Rent" },
        { "name": "Manage" },
        { "name": "Reports" },
    ]
    const UNAUTHENTICATED_PAGES = [
        { "name": "Login", "callback": () => loginWithRedirect() }
    ]

    const UNAUTHENTICATED_SETTINGS = [
        { "name": "Login", "callback": () => loginWithRedirect() }
    ]
    const AUTHENTICATED_SETTINGS = [
        // { "name": "Settings", "to": "/settings" },
        // { "name": "Account", "to": "/settings/account" },
        { "name": "Logout", "callback": () => logout({ "returnTo": window.location.origin }) }
    ]

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    return (
        <AppBar position="static">
            <Container maxWidth="xxl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                    >
                        <img className="logoImg" alt="Holtzman Market Rentals Logo" src="/images/LogoBanner_0001_Green.png" />
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }} >
                            {
                                isAuthenticated && AUTHENTICATED_PAGES.map((page) => (
                                    <MenuItemWithNav key={page.name} to={makeNavUrl(page)} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItemWithNav>
                                ))
                            }
                            {
                                !isAuthenticated && UNAUTHENTICATED_PAGES.map((page) => (
                                    <MenuItemWithNav key={page.name} to={makeNavUrl(page)} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page.name}</Typography>
                                    </MenuItemWithNav>
                                ))
                            }
                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                    >
                        Rental Suite
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        {
                            isAuthenticated && AUTHENTICATED_PAGES.map((page) => (
                                <ButtonWithNav
                                    key={page.name}
                                    to={makeNavUrl(page)}
                                    onClick={handleCloseNavMenu}
                                    sx={{ my: 2, color: "white", display: "block" }}>
                                    {page.name}
                                </ButtonWithNav>
                            ))
                        }
                        {
                            !isAuthenticated && UNAUTHENTICATED_PAGES.map((page) => (
                                <MenuItemWithNav key={page.name} to={makeNavUrl(page)} onClick={page.callback ?? handleCloseNavMenu}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItemWithNav>
                            ))
                        }
                    </Box>
                    <ThemeContext.Consumer>
                        {
                            ([state, dispatch]) => {
                                return <ThemeSwitch sx={{ m: 1 }} onChange={handleThemeSwitch(dispatch)} checked={state === "dark"} />
                            }
                        }
                    </ThemeContext.Consumer>
                    {/* TODO: Implement search? */}
                    {/* <SearchBar placeholder="Search..." onSearch={(e) => { console.log(e) }} /> */}
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar
                                    className="avatar"
                                    alt={user?.nickname ?? "?"}
                                    src={user?.picture ?? ""} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {
                                isAuthenticated && AUTHENTICATED_SETTINGS.map((setting) => (
                                    <MenuItemWithNav key={setting.name} to={makeNavUrl(setting)} onClick={setting.callback ?? handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting.name}</Typography>
                                    </MenuItemWithNav>
                                ))
                            }
                            {
                                !isAuthenticated && UNAUTHENTICATED_SETTINGS.map((setting) => (
                                    <MenuItemWithNav key={setting.name} to={makeNavUrl(setting)} onClick={setting.callback ?? handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting.name}</Typography>
                                    </MenuItemWithNav>
                                ))
                            }
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>

    )
}

export default Header