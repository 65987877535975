import React from "react"
import { Typography, Box, IconButton, Accordion, AccordionSummary, AccordionDetails, Container } from "@mui/material"
import { ArrowForwardIosSharp } from "@mui/icons-material"
import { Refresh } from "@mui/icons-material"

function handleReloadPage() {
    window.location.reload()
}

function ErrorMessage({ message }) {
    const [expanded, setExpanded] = React.useState(false)
    return (
        <Box mt={2}>
            <Typography variant="h4" component="h1" gutterBottom>
                Something went wrong
            </Typography>
            <Typography variant="body1" gutterBottom>
                Please refresh the page and try again.
            </Typography>
            <Typography variant="body1" gutterBottom>
                If the problem persists, please contact support.
            </Typography>
            <IconButton
                size="large"
                aria-label="Reload page"
                onClick={handleReloadPage}
                color="inherit"
            >
                <Refresh />
            </IconButton>
            <Container maxWidth="md">
                <Accordion expanded={expanded} onChange={() => { setExpanded(!expanded) }}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}>
                        <Typography>Error details</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {message}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Container>
        </Box>
    )
}

export default ErrorMessage