import { createContext } from "react"
export const ThemeContext = createContext()

const CHANGE_THEME = "CHANGE_THEME"

export function changeTheme(dispatch, theme) {
    dispatch({
        "type": CHANGE_THEME,
        "payload": { theme }
    })
}

const Actions = {
    CHANGE_THEME: (state, { payload }) => {
        window.localStorage.setItem("theme", JSON.stringify(payload.theme))
        return payload.theme
    }
}

export function ThemeReducer(state, action) {

    const handler = Actions[action.type]
    if (!handler) {
        console.warn("No handler for action: ", action)
        return state
    }

    return handler(state, action)
}
