import React, { useReducer } from "react"
import useAsync from "./useAsync"

const contexts = {}
const DEFAULT_OPTIONS = {
    headers: {
        "Content-Type": "application/json"
    },
}

export function getContext(name) {
    return contexts[name]
}

// TODO: Refactor to have this as a "setup function" which takes a list of names and reducers
// Then the environment can be setup once in the base, and then retrieved everywhere in the app
// Without having to call useEnvironment multiple times
export default function useEnvironment(name, init, reducer, context, loadHandler) {
    const [state, dispatch] = useReducer(reducer, init)

    const EnvironmentProvider = ({ children, ...rest }) => {
        return (
            <context.Provider value={[state, dispatch]} {...rest}>
                {children}
            </context.Provider>
        )
    }

    if (!contexts[name]) {
        contexts[name] = context
    }

    const useApi = (url, options = {}, dependencies = []) => {
        const [loading, error] = useAsync(() => {
            if (dependencies.some(x => x === undefined)) {
                return Promise.reject()
            }

            return fetch(url, { ...DEFAULT_OPTIONS, ...options })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                    return res.json().then(json => Promise.reject(json))
                })
                .then(data => loadHandler(dispatch, data))
        }, dependencies)

        return { loading, error }
    }

    return [EnvironmentProvider, state, useApi, dispatch]
}
